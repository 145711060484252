<template>
  <div class="print-retail-order" v-if="form">
    <div class="h e">
      <div style="width: 25%;"></div>
      <div class="title ta-c flex">定货确认单</div>
      <div class="ta-r" style="width: 25%; padding-bottom: 6pt; font-size: 12pt;">
        合同号：{{form.contractNo}}
        <br />
        NO. {{form.formCode}}
      </div>
    </div>
    
    <table cellpadding="0" cellspacing="0" border="0" v-if="String(hasPrice) === 'true'">
      <thead>
        <th style="width: 60pt;"></th>
        <th style="width: 55pt;"></th>
        <th style="width: 50pt;"></th>
        <th style="width: 40pt;"></th>
        <th style="width: 55pt;"></th>
        <th style="width: 55pt;"></th>
        <th style="width: 55pt;"></th>
        <th style="width: 55pt;"></th>
        <th style="width: 55pt;"></th>
      </thead>
      <tr>
        <td colspan="9" class="inner">
          <table cellpadding="0" cellspacing="0" border="0">
            <tr>
              <td class="ta-c" style="width: 60pt;">定货日期</td>
              <td class="ta-c">{{new Date(form.purchaseTime).format('yyyy/MM/dd')}}</td>
              <td class="ta-c">专卖店电话</td>
              <td>{{shop ? shop.telephone : ""}}</td>
              <td class="ta-c">店名</td>
              <td style="width: 30%;">{{shop ? shop.shopName : ""}}</td>
            </tr>
          </table>
        </td>
      </tr>

      <tr>
        <td colspan="9" class="ta-c">
          <b style="font-size: 16pt;">VIP基本信息</b>
          <div style="font-size: 10.5pt;">（为便于我司为您提供更好的服务，请您尽可能完整填写下列内容，我司将对您填写的内容严格保密）</div>
        </td>
      </tr>
      <tr>
        <td colspan="9" class="ta-c bold" style="font-size: 16pt;">商品信息</td>
      </tr>
      <tr style="font-size: 11pt;">
        <td class="ta-c">系列</td>
        <td colspan="2" class="ta-c">名称/型号</td>
        <td class="ta-c">数量</td>
        <td class="ta-c">统一零售价</td>
        <td class="ta-c">单价</td>
        <td class="ta-c">小计(元)</td>
        <td class="ta-c">交货日期</td>
        <td class="ta-c">备注</td>
      </tr>
      <template v-for="o in form.items">
        <tr style="font-size: 10.5pt; line-height: 1;" :key="o.id">
          <td class="ta-c">{{o.seriesName}}</td>
          <td colspan="2">
            <div>{{o.goodsName}}</div>
            <div class="lh-100" style="font-size: 9pt;">{{$goodsSpecConvert(o.specs)}}</div>
          </td>
          <td class="ta-c">{{o.count}}</td>
          <td class="ta-c">{{$price(o.price)}}</td>
          <td class="ta-c">{{$price(o.realPrice)}}</td>
          <td class="ta-c">{{$price(o.realPrice * o.count)}}</td>
          <td class="ta-c">{{o.deliveryDate ? new Date(o.deliveryDate).format("yyyy-MM-dd") : ""}}</td>
          <td class="ta-c">{{o.info}}</td>
        </tr>
      </template>
      <template v-if="form.items.length < 7">
        <tr v-for="i in (7 - form.items.length)" :key="'e' + i">
          <td>&nbsp;</td>
          <td colspan="2"></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </template>
      <tr>
        <td class="ta-c">合计</td>
        <td colspan="2" style="border-right: none"></td>
        <td class="ta-c" style="border-right: none">{{sums.count}}</td>
        <td style="border-right: none"></td>
        <td style="border-right: none"></td>
        <td class="ta-c" style="border-right: none">{{$price(sums.amount)}}</td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td class="ta-c">备注</td>
        <td colspan="8">{{form.info}}</td>
      </tr>
      <tr>
        <td colspan="9" class="ta-c bold" style="font-size: 16pt;">送货信息</td>
      </tr>
      <tr>
        <td colspan="9" style="font-size: 12pt; padding: 6pt 30pt;">
          <div class="bold">送货时间：以商品的交货日期为参考，具体以双方另行沟通为准。</div>
          <div class="h wrap">
            <div class="checkbox">仅工作日送货（双休日、假日不送货）</div>
            <div class="checkbox">工作日、双休日、假日均可送货</div>
            <div class="checkbox">仅双休日、假日送货</div>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="9" style="font-size: 12pt; padding: 6pt 30pt;">
          <div class="h">
            <div>送货方式：</div>
            <div class="checkbox">送货到户</div>
            <div class="checkbox">送货到楼下</div>
            <div class="checkbox">自提</div>
            <div class="checkbox">其他</div>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="9" style="font-size: 12pt; padding: 6pt 30pt;">
          <div class="h">
            <div>款项支付：</div>
            <div class="checkbox">一次性付清全款</div>
            <div class="checkbox">定金人民币________元，余款于______日内付清。</div>
          </div>
          <div>所有商品需全款付清后方可安排发货。</div>
        </td>
      </tr>
      <tr>
        <td colspan="4" style="border-right: none; font-size: 12pt; padding: 6pt 30pt;">客户姓名：{{form.customerName}}</td>
        <td colspan="5" style="font-size: 12pt;">联系电话：{{form.customerPhone}}</td>
      </tr>
      <tr>
        <td colspan="4" style="border-right: none; font-size: 12pt; padding: 6pt 30pt;">收货人：{{form.customerName}}</td>
        <td colspan="5" style="font-size: 12pt;">联系电话：{{form.customerPhone}}</td>
      </tr>
      <tr>
        <td colspan="9" style="font-size: 12pt; padding: 6pt 30pt;">送货地址：{{form.provinceName}}{{form.cityName}}{{form.districtName}}{{form.customerAddress}}</td>
      </tr>
      <tr>
        <td colspan="4" style="border-right: none; font-size: 12pt; padding: 6pt 30pt;">客户生日：</td>
        <td colspan="3" style="border-right: none; font-size: 12pt;">宝宝姓名：</td>
        <td colspan="2" style="font-size: 12pt;">宝宝年龄：</td>
      </tr>
      <tr>
        <td colspan="9" style="font-size: 12pt; padding: 6pt 30pt;">
          <div class="h">
            <div>其他：1、是否属于定制款？&emsp;</div>
            <div class="checkbox">是</div>
            <div class="checkbox">否</div>
            <div>&emsp;2、是否属于不予退换的特价品？&emsp;</div>
            <div class="checkbox">是</div>
            <div class="checkbox">否</div>
          </div>
          <div class="h">
            <div style="text-indent: 3em;">3、是否属于样品？&emsp;</div>
            <div class="checkbox">是</div>
            <div class="checkbox">否</div>
            <div>&emsp;&emsp;样品瑕疵说明：________________________</div>
          </div>
        </td>
      </tr>
    </table>

    <table cellpadding="0" cellspacing="0" border="0" v-else>
      <thead>
        <th style="width: 60pt;"></th>
        <th style="width: 70pt;"></th>
        <th style="width: 70pt;"></th>
        <th style="width: 40pt;"></th>
        <th style="width: 60pt;"></th>
        <th style="width: 60pt;"></th>
        <th style="width: 60pt;"></th>
        <th style="width: 60pt;"></th>
      </thead>
      <tr>
        <td colspan="8" class="inner">
          <table cellpadding="0" cellspacing="0" border="0">
            <tr>
              <td class="ta-c" style="width: 60pt;">定货日期</td>
              <td class="ta-c">{{new Date(form.purchaseTime).format('yyyy/MM/dd')}}</td>
              <td class="ta-c">专卖店电话</td>
              <td>{{shop ? shop.telephone : ""}}</td>
              <td class="ta-c">店名</td>
              <td style="width: 30%;">{{shop ? shop.shopName : ""}}</td>
            </tr>
          </table>
        </td>
      </tr>

      <tr>
        <td colspan="8" class="ta-c">
          <b style="font-size: 16pt;">VIP基本信息</b>
          <div style="font-size: 10.5pt;">（为便于我司为您提供更好的服务，请您尽可能完整填写下列内容，我司将对您填写的内容严格保密）</div>
        </td>
      </tr>
      <tr>
        <td colspan="8" class="ta-c bold" style="font-size: 16pt;">商品信息</td>
      </tr>
      <tr>
        <td class="ta-c">系列</td>
        <td colspan="2" class="ta-c">名称/型号</td>
        <td class="ta-c">数量</td>
        <td class="ta-c">单价</td>
        <td class="ta-c">小计(元)</td>
        <td class="ta-c">交货日期</td>
        <td class="ta-c">备注</td>
      </tr>
      <template v-for="o in form.items">
        <tr style="font-size: 10.5pt; line-height: 1;" :key="o.id">
          <td class="ta-c">{{o.seriesName}}</td>
          <td colspan="2">
            <div>{{o.goodsName}}</div>
            <div class="lh-100" style="font-size: 9pt;">{{$goodsSpecConvert(o.specs)}}</div>
          </td>
          <td class="ta-c">{{o.count}}</td>
          <td class="ta-c">{{$price(o.realPrice)}}</td>
          <td class="ta-c">{{$price(o.realPrice * o.count)}}</td>
          <td class="ta-c">{{o.deliveryDate ? new Date(o.deliveryDate).format("yyyy-MM-dd") : ""}}</td>
          <td class="ta-c">{{o.info}}</td>
        </tr>
      </template>
      <template v-if="form.items.length < 8">
        <tr v-for="i in (8 - form.items.length)" :key="'e' + i">
          <td>&nbsp;</td>
          <td colspan="2"></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </template>
      <tr>
        <td class="ta-c">合计</td>
        <td colspan="2" style="border-right: none"></td>
        <td class="ta-c" style="border-right: none">{{sums.count}}</td>
        <td style="border-right: none"></td>
        <td class="ta-c" style="border-right: none">{{$price(sums.amount)}}</td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td class="ta-c">备注</td>
        <td colspan="7">{{form.info}}</td>
      </tr>
      <tr>
        <td colspan="8" class="ta-c bold" style="font-size: 16pt;">送货信息</td>
      </tr>
      <tr>
        <td colspan="8" style="font-size: 12pt; padding: 6pt 30pt;">
          <div class="bold">送货时间：以商品的交货日期为参考，具体以双方另行沟通为准。</div>
          <div class="h wrap">
            <div class="checkbox">仅工作日送货（双休日、假日不送货）</div>
            <div class="checkbox">工作日、双休日、假日均可送货</div>
            <div class="checkbox">仅双休日、假日送货</div>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="8" style="font-size: 12pt; padding: 6pt 30pt;">
          <div class="h">
            <div>送货方式：</div>
            <div class="checkbox">送货到户</div>
            <div class="checkbox">送货到楼下</div>
            <div class="checkbox">自提</div>
            <div class="checkbox">其他</div>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="8" style="font-size: 12pt; padding: 6pt 30pt;">
          <div class="h">
            <div>款项支付：</div>
            <div class="checkbox">一次性付清全款</div>
            <div class="checkbox">定金人民币________元，余款于______日内付清。</div>
          </div>
          <div>所有商品需全款付清后方可安排发货。</div>
        </td>
      </tr>
      <tr>
        <td colspan="3" style="border-right: none; font-size: 12pt; padding: 6pt 30pt;">客户姓名：{{form.customerName}}</td>
        <td colspan="5" style="font-size: 12pt;">联系电话：{{form.customerPhone}}</td>
      </tr>
      <tr>
        <td colspan="3" style="border-right: none; font-size: 12pt; padding: 6pt 30pt;">收货人：{{form.customerName}}</td>
        <td colspan="5" style="font-size: 12pt;">联系电话：{{form.customerPhone}}</td>
      </tr>
      <tr>
        <td colspan="8" style="font-size: 12pt; padding: 6pt 30pt;">送货地址：{{form.provinceName}}{{form.cityName}}{{form.districtName}}{{form.customerAddress}}</td>
      </tr>
      <tr>
        <td colspan="3" style="border-right: none; font-size: 12pt; padding: 6pt 30pt;">客户生日：</td>
        <td colspan="3" style="border-right: none; font-size: 12pt;">宝宝姓名：</td>
        <td colspan="2" style="font-size: 12pt;">宝宝年龄：</td>
      </tr>
      <tr>
        <td colspan="8" style="font-size: 12pt; padding: 6pt 30pt;">
          <div class="h">
            <div>其他：1、是否属于定制款？&emsp;</div>
            <div class="checkbox">是</div>
            <div class="checkbox">否</div>
            <div>&emsp;2、是否属于不予退换的特价品？&emsp;</div>
            <div class="checkbox">是</div>
            <div class="checkbox">否</div>
          </div>
          <div class="h">
            <div style="text-indent: 3em;">3、是否属于样品？&emsp;</div>
            <div class="checkbox">是</div>
            <div class="checkbox">否</div>
            <div>&emsp;&emsp;样品瑕疵说明：________________________</div>
          </div>
        </td>
      </tr>
    </table>
    <div class="h sa" style="font-size: 12pt;">
      <div>客 户 签 字 ：</div>
      <div>销 售 代 表 签 字 ：</div>
    </div>
  </div>
</template>

<script>
import { get } from "@/api/shopOrder";
import { getById } from "@/api/shopInformation";
export default {
  props: {
    id: String | Number,
    hasPrice: Boolean
  },
  data() {
    return {
      form: null,
      shop: null,
    };
  },
  computed: {
    sums() {
      let count = 0,
        amount = 0;
      if (this.form && this.form.items && this.form.items.length) {
        this.form.items.forEach((o) => {
          count += o.count;
          amount += o.realPrice * o.count;
        });
      }
      count = Math.round(count).toFixed(2);
      return {
        count,
        amount,
      };
    },
  },
  methods: {
    load() {
      get(this.id)
        .then((res) => {
          (res.items || []).forEach((o) => {
            o.count = o.quantity - o.cancelCount;
          });
          res.items = res.items.filter((o) => o.count > 0);
          this.form = res;
          this.loadShopInfo(res.shopId);
        })
        .catch((e) => {
          this.$emit("error", e);
        });
    },
    loadShopInfo(id) {
      getById(id)
        .then((res) => {
          this.shop = res;
          this.$nextTick((_) => {
            this.$emit("loaded");
          });
        })
        .catch((e) => {
          this.$emit("error", e);
        });
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style lang="less">
.print-retail-order {
  .title {
    font-size: 36pt !important;
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
      Microsoft YaHei, Arial, sans-serif !important;
    padding: 0 !important;
    line-height: 1;
  }
  table {
    table-layout: fixed;
    margin: 15pt 0 !important;
    font-size: 12pt;
  }

  .inner-cell {
    padding: 0 !important;
    > table {
      margin: 0 !important;
      border: none !important;
      tr:first-child {
        td {
          border-top: none !important;
        }
      }
      td:last-child {
        border-right: none !important;
      }
    }
  }
  .checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10pt;

    &:before {
      content: "";
      position: relative;
      width: 8pt;
      height: 8pt;
      margin-right: 2pt;
      border: #000 solid 1px;
    }
  }
}
</style>