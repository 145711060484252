
import request from '@/utils/request'



export function checked(data) {
  return request({
    url: 'api/stocktaking/check',
    method: 'put',
    data
  })
}
export function getPurchaseGoodsPrice(params) {
  return request({
    url: 'api/purchaseGoods/price',
    method: 'get',
    params
  })
}
export function get(params) {
    return request({
      url: 'api/stocktaking',
      method: 'get',
      paramsid
    })
  }
export function getById(id) {
    return request({
      url: 'api/stocktaking/' + id,
      method: 'get'
    })
  }
export function add(data) {
  return request({
    url: 'api/stocktaking',
    method: 'post',
    data
  })
}

export function del(id) {
  return request({
    url: 'api/stocktaking/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: 'api/stocktaking',
    method: 'put',
    data
  })
}

