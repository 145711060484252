<template>
  <div class="print-retail-consignment" v-if="form">
    <div class="title ta-c bold">{{form.brandName}}发货单</div>

    <table class="no-border" cellpadding="0" cellspacing="0" border="0">
      <tr valign="top">
        <td style="width: 130pt;">合同单号：{{form.saleForm.contractNo}}</td>
        <td>销售单号：{{form.dependFormCode}}</td>
        <td style="width: 160pt;">发货单号：{{form.formCode}}</td>
        <td style="width: 140pt;">出库库房：{{form.warehouseName}}</td>
      </tr>
      <tr valign="top">
        <td>客户名称：{{form.customerName}}</td>
        <td>客户电话：{{form.customerPhone}}</td>
        <td colspan="2">收货地址：{{form.provinceName}}{{form.cityName}}{{form.districtName}}{{form.customerAddress}}</td>
        </tr>
      <tr valign="top">
        <td>打印日期：{{new Date().format("yyyy年M月d日")}}</td>
        <td>销售门店：{{form.saleForm.shopName}}</td>
        <td>销售人员：{{form.saleForm.salesmanName}}</td>
        <td>联系电话：{{form.saleForm.salesmanPhone}}</td>
        </tr>
    </table>

    <table cellpadding="0" cellspacing="0" border="0">
      <tr class="bold">
        <td class="ta-c" style="width: 25pt;">序号</td>
        <td class="ta-c" style="width: 75pt;">ERP编码</td>
        <td class="ta-c">商品名称</td>
        <td class="ta-c" style="width: 150pt;">规格型号</td>
        <td class="ta-c" style="width: 30pt;">数量</td>
        <td class="ta-c" style="width: 30pt;">单位</td>
        <td class="ta-c" style="width: 50pt;">送货时间</td>
        <td class="ta-c" style="width: 60pt;">备注</td>
      </tr>
      <template v-for="(o, i) in form.items">
        <tr :key="o.id">
          <td class="ta-c">{{i + 1}}</td>
          <td class="ta-c">{{o.erpCode}}</td>
          <td class="ta-c">{{o.goodsName}}</td>
          <td class="ta-c">{{$goodsSpecConvert(o.specs)}}</td>
          <td class="ta-c">{{o.quantity}}</td>
          <td class="ta-c"></td>
          <td class="ta-c">{{new Date(o.deliveryDate).format("yyyy/MM/dd")}}</td>
          <td class="ta-c">{{o.info}}</td>
        </tr>
      </template>
      <template v-if="form.items.length < 10">
        <tr v-for="i in (10 - form.items.length)" :key="'e' + i">
          <td class="ta-c">{{form.items.length + i}}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </template>
      <tr class="bold">
        <td colspan="2" class="ta-c">总计数量</td>
        <td></td>
        <td></td>
        <td class="ta-c">{{totalQuantity}}</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td colspan="2" class="ta-c">备注</td>
        <td colspan="6">{{form.info}}</td>
      </tr>
      <tr>
        <td colspan="2" class="ta-c">服务评价</td>
        <td colspan="6" class="inner-cell">
          <table cellpadding="0" cellspacing="0" border="0">
            <tr>
              <td class="ta-c" style="width: 75pt;">服务内容</td>
              <td class="ta-c">完成情况</td>
            </tr>
            <tr>
              <td>数量是否吻合</td>
              <td>
                <div class="h m">
                  <div class="checkbox">是</div>
                  <div class="checkbox">否</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>包装已拆开查看产品有无损坏</td>
              <td>
                <div class="h m">
                  <div class="checkbox">是</div>
                  <div class="checkbox">否</div>
                </div>
                <div>具体说明：</div>
              </td>
            </tr>
            <tr>
              <td>送货师傅服务态度评价</td>
              <td>
                <div class="h m">
                  <div class="checkbox">十分满意</div>
                  <div class="checkbox">满意</div>
                  <div class="checkbox">一般</div>
                  <div class="checkbox">不满意</div>
                </div>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td colspan="8">尊敬的客户：感谢您对我们品牌的信任和支持，为了更好的为您服务，特向您征集此次服务意见或建议，您的宝贵意见将作为我们{{form.brandName}}改进的依据。您的建议十分重要！请您再百忙之中填写此表，我们再次表示深深的感谢！</td>
      </tr>
      <tr>
        <td colspan="2" class="ta-c">注意事项：</td>
        <td colspan="6">签字视同已对产品进行验收，确认以上产品无破损，无污渍；本送货单请于30天内提货，过期作废；如有错漏，请于2天内和本公司联系。</td>
      </tr>
    </table>

    <div class="h r">
      <div style="width: 24%;">客户签收：</div>
      <div style="width: 24%;">送货人签字：</div>
      <div style="width: 24%;">仓管员：</div>
      <div style="width: 24%;">制单人：{{form.createBy}}</div>
    </div>
  </div>
</template>

<script>
import { get } from "@/api/deliveryNotice";

export default {
  props: {
    id: String | Number,
  },
  data() {
    return {
      form: null,
    };
  },
  computed: {
    totalQuantity() {
      let n = 0;
      (this.form.items || []).forEach((o) => {
        n += o.quantity || 0;
      });
      return n;
    },
  },
  methods: {
    load() {
      get(this.id)
        .then((res) => {
          this.form = res;
          this.$nextTick(_ => {
            this.$emit("loaded");
          });
        })
        .catch((e) => {
          this.$emit("error", e);
        });
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style lang="less">
.print-retail-consignment {
  table {
    table-layout: fixed;
    margin: 15pt 0 !important;
  }

  .inner-cell {
    padding: 0 !important;
    > table {
      margin: 0 !important;
      border: none !important;
      tr:first-child {
        td {
          border-top: none !important;
        }
      }
      td:last-child {
        border-right: none !important;
      }
    }
  }
  .checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90pt;

    &:before {
      content: "";
      position: relative;
      width: 8pt;
      height: 8pt;
      margin-right: 5pt;
      border: #000 solid 1px;
    }
  }
}
</style>