<template>
  <div v-if="form">
    <div class="title ta-c">商品批发销售单</div>
    <div class="h sb">
      <div>
        销售单号：{{form.formCode}}
        <br />
        销售日期：{{new Date(form.purchaseTime).format("yyyy年M月d日")}}
      </div>
      <div>
        &emsp;经销商：{{form.buyer.name}}
        <span v-if="form.buyerErpId">({{form.buyerErpId}})</span>
        <br />
        &emsp;收货人：{{form.receiverName}}&nbsp;({{form.receiverPhone}})
        <br />
        收货地址：{{form.recProvince}}{{form.recCity}}{{form.recDistrict}}{{form.recAddress}}
      </div>
    </div>
    <div>商品明细：</div>
    <table cellpadding="0" cellspacing="0" border="0">
      <tr>
        <td style="width: 37%;">商品名称</td>
        <td style="width: 25%;">规格</td>
        <td style="width: 10%;" class="ta-r">单价</td>
        <td style="width: 8%;" class="ta-r">数量</td>
        <td style="width: 20%;">备注</td>
      </tr>
      <template v-for="o in form.items">
        <tr :key="o.id">
          <td>
            <template v-if="o.isGift">【赠品】</template>
            {{o.goodsName}}
          </td>
          <td>{{$goodsSpecConvert(o.goodsSpec)}}</td>
          <td class="ta-r">{{$price(o.realPrice)}}</td>
          <td class="ta-r">{{o.count}}</td>
          <td>{{o.info}}</td>
        </tr>
      </template>
    </table>
    <div>&emsp;&emsp;摘要：{{form.info}}</div>
    <div v-if="form.useRebate && form.rebateAmount > 0">返利抵扣：{{$price(form.rebateAmount)}}</div>
    <div>&emsp;总金额：{{$price(form.totalMoney - form.discountAmount - form.rebateAmount)}}（大写：{{$chn((form.totalMoney - form.discountAmount - form.rebateAmount) / 100)}}）</div>
  </div>
</template>

<script>
import { get } from "@/api/distributorOrder";

export default {
  props: {
    id: String | Number
  },
  data() {
    return {
      form: null
    };
  },
  methods: {
    load() {
      get(this.id)
        .then(res => {
          this.form = res;
          this.$nextTick(_ => {
            this.$emit("loaded");
          });
        })
        .catch(e => {
          this.$emit("error", e);
        });
    }
  },
  mounted() {
    this.load();
  }
};
</script>