<template>
  <div v-if="form">
    <div class="title ta-c">零售出库单</div>
    <div class="h sb">
      <div style="width: 48%;">
        出库单号：{{form.formCode}}
        <br />
        发货单号：{{form.dependFormCode}}
      </div>
      <div style="width: 48%;">
        出库库房：{{form.warehouseName}}
        <br />
        出库时间：{{new Date(form.formTime).format("yyyy年M月d日")}}
        <br />
        &emsp;出库人：{{form.manager}}
      </div>
    </div>
    <div>发货明细：</div>
    <table cellpadding="0" cellspacing="0" border="0">
      <tr>
        <td style="width: 37%;">商品名称</td>
        <td style="width: 25%;">规格</td>
        <td style="width: 8%;" class="ta-r">出库数量</td>
      </tr>
      <template v-for="o in form.items">
        <tr :key="o.id">
          <td>{{o.goodsName}}</td>
          <td>{{$goodsSpecConvert(o.specs)}}</td>
          <td class="ta-r">{{o.realCount}}</td>
        </tr>
      </template>
    </table>
    <div>&emsp;&emsp;备注：{{form.info}}</div>
  </div>
</template>

<script>
import { get } from "@/api/storageManage";

export default {
  props: {
    id: String | Number,
  },
  data() {
    return {
      form: null,
    };
  },
  methods: {
    load() {
      get(this.id)
        .then((res) => {
          this.form = res;
          this.$nextTick((_) => {
            this.$emit("loaded");
          });
        })
        .catch((e) => {
          this.$emit("error", e);
        });
    },
  },
  mounted() {
    this.load();
  },
};
</script>