<template>
  <div v-if="form">
    <div class="title ta-c">盘点单</div>
    <div class="h sb">
      <div>
        调拨单号：{{form.formCode}}
        <br />
        调拨日期：{{new Date(form.createAt).format("yyyy年M月d日")}}
      </div>
      <div>盘点库房：{{form.warehouseName}}</div>
    </div>
    <div>调拨明细：</div>
    <table cellpadding="0" cellspacing="0" border="0">
      <tr>
        <td style="width: 40%;">商品名称</td>
        <td style="width: 25%;">规格</td>
        <td style="width: 8%;">品牌</td>
        <td style="width: 7%;">数量</td>
        <td style="width: 20%;">备注</td>
      </tr>
      <template v-for="o in form.items">
        <tr :key="o.id">
          <td>{{o.goodsName}}</td>
          <td>{{$goodsSpecConvert(o.goodsSpec)}}</td>
          <td>{{o.goodsBrandName}}</td>
          <td>{{o.realCount}}</td>
          <td>{{o.info}}</td>
        </tr>
      </template>
    </table>
    <div>&emsp;&emsp;备注：{{form.info}}</div>
  </div>
</template>

<script>
import { getById } from "@/api/inventory";

export default {
  props: {
    id: String | Number,
  },
  data() {
    return {
      form: null,
    };
  },
  methods: {
    load() {
      getById(this.id)
        .then((res) => {
          this.form = res;
          this.$nextTick((_) => {
            this.$emit("loaded");
          });
        })
        .catch((e) => {
          this.$emit("error", e);
        });
    },
  },
  mounted() {
    this.load();
  },
};
</script>