import request from '@/utils/request'

export function get(id) {
  return request({
    url: "api/allocationForm/" + id,
    method: "get"
  })
}

export function add(data) {
  return request({
    url: "api/allocationForm",
    method: "post",
    data
  })
}

export function edit(data) {
  return request({
    url: "api/allocationForm",
    method: "put",
    data
  })
}

export function del(id) {
  return request({
    url: "api/allocationForm/" + id,
    method: "delete"
  })
}

export function audit(data) {
  return request({
    url: "api/allocationForm/check",
    method: "post",
    data
  })
}
export function cancelAudit(id) {
    return request({
      url: "api/allocationForm/rollback/"+id,
      method: "put"
    })  
}