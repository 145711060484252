<template>
  <div v-if="form">
    <div class="title ta-c">批发销售发货通知单</div>
    <div class="h sb">
      <div>
        发货单号：{{form.formCode}}
        <br />
        发货日期：{{new Date(form.planeTime).format("yyyy年M月d日")}}（预计）
        <br />
        发货库房：{{form.warehouseName}}
      </div>
      <div>
        &emsp;收货人：{{form.receiverName}}（{{form.phone}}）
        <br />
        收货地址：{{form.recProvince}}{{form.recCity}}{{form.recDistrict}}{{form.recAddress}}
      </div>
    </div>
    <div>发货明细：</div>
    <table cellpadding="0" cellspacing="0" border="0">
      <tr>
        <td style="width: 37%;">商品名称</td>
        <td style="width: 25%;">规格</td>
        <td style="width: 10%;" class="ta-r">单价</td>
        <td style="width: 8%;" class="ta-r">数量</td>
      </tr>
      <template v-for="o in form.items">
        <tr :key="o.id">
          <td>{{o.goodsName}}</td>
          <td>{{$goodsSpecConvert(o.specName)}}</td>
          <td class="ta-r">￥{{(o.goodsPrice / 100).toFixed(2)}}</td>
          <td class="ta-r">{{o.goodsCount}}</td>
        </tr>
      </template>
    </table>
    <div>&emsp;&emsp;备注：{{form.info}}</div>
    <div>&emsp;总金额：￥{{(form.goodsPriceAmount / 100).toFixed(2)}}（大写：{{$chn(form.goodsPriceAmount / 100)}}）&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;运费：￥{{(form.freight / 100).toFixed(2)}}（大写：{{$chn(form.freight / 100)}}）</div>
  </div>
</template>

<script>
import { get } from "@/api/consignment";

export default {
  props: {
    id: String | Number
  },
  data() {
    return {
      form: null
    };
  },
  methods: {
    load() {
      get(this.id)
        .then(res => {
          this.form = res;
          this.$nextTick(_ => {
            this.$emit("loaded");
          });
        })
        .catch(e => {
          this.$emit("error", e);
        });
    }
  },
  mounted() {
    this.load();
  }
};
</script>